import { render, staticRenderFns } from "./allTokens.vue?vue&type=template&id=00d3bda8&scoped=true&"
var script = {}
import style0 from "./allTokens.vue?vue&type=style&index=0&id=00d3bda8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d3bda8",
  null
  
)

export default component.exports